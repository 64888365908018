<script lang="ts" setup>
import Player, { type Options } from "@vimeo/player";

const props = withDefaults(
  defineProps<{
    playerHeight?: number;
    playerWidth?: number;
    playerClass?: string;
    options?: Options;
    videoId?: number;
    videoUrl?: string;
    loop?: boolean;
    autoplay?: boolean;
    controls?: boolean;
    eventsToEmit?: string[];
  }>(),
  {
    playerHeight: 340,
    playerWidth: 600,
    options: () => ({}),
    loop: false,
    autoplay: true,
    controls: true,
    eventsToEmit: () => [],
  },
);

const playing = ref(false);

const emit = defineEmits([
  "play",
  "playing",
  "pause",
  "ended",
  "timeupdate",
  "progress",
  "seeking",
  "seeked",
  "texttrackchange",
  "chapterchange",
  "cuechange",
  "cuepoint",
  "volumechange",
  "playbackratechange",
  "bufferstart",
  "bufferend",
  "ready",
  "error",
  "loaded",
  "durationchange",
  "fullscreenchange",
  "qualitychange",
  "camerachange",
  "resize",
]);

const playerModel = defineModel<Player>();

const elementRef = ref<HTMLIFrameElement>();

const mergeOptions = ({ id, url }: { id?: number; url?: string }) => {
  const opts: Options = {
    width: props.playerWidth,
    height: props.playerHeight,
    loop: props.loop,
    autoplay: props.autoplay,
    controls: props.controls,
    portrait: false,
    title: false,
    byline: false,
  };

  if (unref(url)) {
    opts.url = unref(url);
  }

  if (unref(id)) {
    opts.id = unref(id);
  }

  return Object.assign(opts, props.options);
};

watch(elementRef, () => {
  if (elementRef.value && !playerModel.value) {
    const player = new Player(
      elementRef.value,
      mergeOptions({ id: props.videoId, url: props.videoUrl }),
    );

    player
      .ready()
      .then(() => {
        if (props.playerClass) {
          elementRef.value
            ?.querySelector("iframe")
            ?.classList.add(...props.playerClass.split(" "));
        }

        player.pause();

        emit("ready", player);

        playerModel.value = player;
      })
      .catch((error: unknown) => {
        emit("error", error, player);
      });

    props.eventsToEmit.forEach((event) => {
      player.on(event, (data) => {
        emit(event as keyof typeof emit, data, player);
      });
    });

    player.on("play", () => (playing.value = true));
    player.on("pause", () => (playing.value = false));
  }
});

onBeforeUnmount(() => {
  playerModel.value?.unload();
  playerModel.value = undefined;
});

watch(toRef(props, "videoId"), (id) =>
  playerModel.value?.loadVideo(mergeOptions({ id })),
);
watch(toRef(props, "videoUrl"), (url) =>
  playerModel.value?.loadVideo(mergeOptions({ url })),
);
watch(toRef(props, "controls"), () =>
  playerModel.value?.loadVideo(
    mergeOptions({ url: props.videoUrl, id: props.videoId }),
  ),
);
</script>

<template>
  <div class="relative">
    <div
      ref="elementRef"
      class="vimeo-player"
    />

    <div
      class="play-button transition-all"
      :class="{ 'opacity-0': playing }"
    >
      <NuxtIcon
        name="ussif:play"
        class="icon-play"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.vimeo-player {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 */

  @media screen and (width <= 1023px) {
    width: calc(100vw - 48px);
    height: calc(56.25vw - 24px);
    padding: 0;
    margin: 0;
  }
}

.play-button {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  pointer-events: none;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 50%;

  .icon-play:deep(svg) {
    background-color: #39f;
    height: 50px;
    width: 125px;
    mask:
      linear-gradient(-68deg, #fff 72%, transparent 72%) top left,
      linear-gradient(-248deg, #fff 72%, transparent 72%) bottom right;
    mask-repeat: no-repeat;
    mask-composite: intersect;
  }
}
</style>

<style>
.vimeo-player iframe {
  border-radius: 8px;
  max-width: 100%;

  @media screen and (width <= 1023px) {
    width: 100%;
    height: 100%;
  }
}
</style>
