import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";
import type { TypeDownloadTypeSkeleton } from "./TypeDownloadType";
import type { TypeSubtopicSkeleton } from "./TypeSubtopic";
import type { TypeTopicSkeleton } from "./TypeTopic";

export type TypeDownloadFields = {
  title: EntryFieldTypes.Symbol;
  shortTitle?: EntryFieldTypes.Symbol;
  slug?: EntryFieldTypes.Symbol;
  topicOrSubtopic?: EntryFieldTypes.EntryLink<TypeSubtopicSkeleton | TypeTopicSkeleton>;
  downloadType: EntryFieldTypes.EntryLink<TypeDownloadTypeSkeleton>;
  file?: EntryFieldTypes.AssetLink;
  fileType: EntryFieldTypes.Symbol<"DOC" | "JPG" | "PDF" | "URL" | "VIDEO">;
  image?: EntryFieldTypes.AssetLink;
  videoUrl?: EntryFieldTypes.Symbol;
  videoThumbnail?: EntryFieldTypes.AssetLink;
  formHandlerUrl?: EntryFieldTypes.Symbol;
  date: EntryFieldTypes.Date;
  hidden: EntryFieldTypes.Boolean;
};

export type TypeDownloadSkeleton = EntrySkeletonType<TypeDownloadFields, "download">;
export type TypeDownload<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypeDownloadSkeleton, Modifiers, Locales>;

export function isTypeDownload<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypeDownload<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === "download";
}
