<script lang="ts" setup>
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import type { Entry } from "contentful";
import nodeRenderers from "~/lib/node-renderers.js";
import { getYear, getMonth, getDay } from "~/lib/date-time";
import type { TypeArticleSkeleton } from "~/types/contentful";

const props = defineProps<{
  event: Entry<
    TypeArticleSkeleton,
    "WITHOUT_UNRESOLVABLE_LINKS",
    "en-GB"
  >;
}>();

const currentYear = computed(() => new Date().getFullYear());
const eventYear = computed(() => getYear(props.event.fields.publishDate));

const url = computed(() => getUrl(props.event));
</script>

<template>
  <div
    class="card-block--event grid grid-cols-12 gap-6"
  >
    <div
      class="col-span-12 flex justify-between text-2xl md:col-span-4 md:text-center lg:col-span-5"
    >
      <div
        v-if="event.fields.publishDate"
        class="mr-4 flex w-1/3 flex-col md:items-center"
      >
        <div v-if="eventYear > currentYear">
          {{ eventYear }}
        </div>
        <template v-else>
          <div class="uppercase">
            {{ getMonth(event.fields.publishDate) }}
          </div>
          <div>{{ getDay(event.fields.publishDate) }}</div>
        </template>
      </div>

      <NuxtLink
        :to="url"
        :class="{ 'pointer-events-none': !url }"
      >
        <NuxtImg
          v-if="event.fields.image?.fields"
          class="mt-px block h-[50vw] max-h-[156px] w-[70vw] rounded-xl object-cover md:max-w-[156px]"
          :src="event.fields.image?.fields.file?.url"
          :alt="event.fields.image?.fields.description || 'event image'"
          width="156"
          loading="lazy"
        />
      </NuxtLink>
    </div>

    <section class="col-span-12 block text-left md:col-span-8 lg:col-span-7">
      <h4
        class="mb-4 md:text-2xl"
        :class="{ 'pointer-events-none': !url }"
      >
        {{ event.fields.title }}
      </h4>

      <NuxtLink
        v-if="url"
        :to="url"
        class="btn mt-5 border-blue transition-all duration-200 hover:border-white"
      >
        View details
      </NuxtLink>
    </section>
  </div>
</template>
