<script lang="ts" setup>
import type { Entry } from "contentful";
import {
  isTypeArticle,
  isTypeDownload,
  type TypeArticleSkeleton,
  type TypeDownloadSkeleton,
} from "~/types/contentful";
import { hasImage } from "~/lib/article/type-guards.js";
import { formatDate } from "~/lib/date-time";

const props = defineProps<{
  item: Entry<
    TypeArticleSkeleton | TypeDownloadSkeleton,
    "WITHOUT_UNRESOLVABLE_LINKS",
    "en-GB"
  >;
}>();

const type = computed(() => {
  if (isTypeArticle(props.item)) {
    return props.item.fields.articleType?.fields.title;
  }

  if (isTypeDownload(props.item)) {
    return props.item.fields.downloadType?.fields.title;
  }

  return undefined;
});

const date = computed(() => {
  if (isTypeArticle(props.item)) {
    return formatDate(new Date(props.item.fields.publishDate).toISOString());
  } else if (isTypeDownload(props.item)) {
    return formatDate(new Date(props.item.fields.date).toISOString());
  } else {
    return undefined;
  }
});

const itemUrl = (
  item: Entry<
    TypeArticleSkeleton | TypeDownloadSkeleton,
    "WITHOUT_UNRESOLVABLE_LINKS",
    "en-GB"
  >,
) => (isTypeArticle(item) ? getUrl(item) : "");

const itemImage = computed(() =>
  hasImage(props.item)
    ? props.item.fields.image
    : isTypeDownload(props.item) && props.item.fields.downloadType?.fields.image
      ? props.item.fields.downloadType.fields.image
      : "",
);
</script>

<template>
  <div class="hub-item group relative">
    <div class="absolute z-0 size-full shadow" />

    <section
      class="relative z-10 grid grid-cols-12 gap-4 rounded-xl bg-white p-8"
    >
      <div
        v-if="itemImage"
        class="col-span-6 col-start-4"
      >
        <NuxtLink
          :to="itemUrl(item)"
          class="!flex justify-center"
        >
          <NuxtImg
            v-if="itemImage && itemImage.fields.file"
            class="hub-item-image mb-4 max-h-40 !object-contain"
            :src="itemImage.fields.file.url"
            :alt="itemImage.fields.title"
            :width="220"
            :height="300"
            fit="contain"
            loading="lazy"
          />
        </NuxtLink>
      </div>

      <div class="col-span-3 flex justify-end">
        <NuxtLink
          v-if="isTypeDownload(item)"
          :to="item.fields.file?.fields.file?.url"
          target="_blank"
        >
          <NuxtIcon
            class="inline-flex size-10 items-center justify-center rounded-md bg-blue"
            name="ussif:download"
          />
        </NuxtLink>
      </div>

      <div class="col-span-12 flex flex-col">
        <h4 class="mb-5">
          <NuxtLink
            :to="
              isTypeDownload(item)
                ? item.fields.file?.fields.file?.url
                : itemUrl(item)
            "
          >
            {{ item.fields.title }}
          </NuxtLink>
        </h4>

        <div class="mb-4 flex items-center">
          <UiBadge
            v-if="type"
            bg-colour="#e8e8e8"
          >
            {{ type }}
          </UiBadge>
        </div>
      </div>

      <div class="col-span-12">
        <UiDate
          :date="date"
          hide-slash
        />
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
.hub-item {
  &__image:deep(.header-info__image) {
    img {
      @apply m-auto max-h-[230px];
    }
  }
}

.hub-item-image {
  filter: drop-shadow(0 0 14px #e0e0e0) drop-shadow(0 0 14px #e0e0e0);
}

.shadow {
  box-shadow: #f0f0f0 0 0 100px 0;
}
</style>
