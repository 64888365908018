<script lang="ts" setup>
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import type { TypeBlockTextGrid } from "~/types/contentful";
import nodeRenderers from "~/lib/node-renderers.js";

defineProps<{
  fields: TypeBlockTextGrid<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
}>();
</script>

<template>
  <UiContainer class="relative">
    <section class="border-b-8 border-blue pb-10">
      <UiSectionTitle
        :title="fields.title"
        :subtitle="fields.intro"
      >
        <div class="btn-wrap--secondary mb-20 mt-10">
          <RichTextRenderer
            :document="fields.link"
            :node-renderers="nodeRenderers"
          />
        </div>
      </UiSectionTitle>

      <div class="-mx-8 flex flex-wrap justify-center">
        <div
          v-for="item in fields.items"
          :key="item?.sys.id"
          class="md:lg-1/2 px-8 pb-8 text-center lg:w-1/3"
        >
          <div class="mb-4 flex items-center justify-center">
            <NuxtIcon
              name="ussif:slash-wider"
              class="relative top-px mr-3 text-blue lg:-ml-3 lg:text-xl"
            />
            <h4>{{ item?.fields.title }}</h4>
          </div>
          <div class="rich-text">
            <RichTextRenderer
              :document="item?.fields.content"
              :node-renderers="nodeRenderers"
            />
          </div>
          <!-- <CardBlockText :fields="item?.fields" /> -->
        </div>
      </div>
    </section>
  </UiContainer>
</template>
